@keyframes loader {
  0% {
    width: 10%;
    transform: rotate(0deg);
  }
  10% {
    left: 25%;
    transform: rotate(0deg);
  }
  20% {
    left: 25%;
  }
  30% {
    left: 25%;
  }
  50% {
    left: 30%;
    width: 35%;
  }
  70% {
    width: 30%;
    left: 18%;
    transform: rotate(240deg);
  }
  90% {
    width: 30%;
    left: 10%;
  }
  100% {
    width: 2%;
    left: 25%;
    transform: rotate(360deg);
  }
}
@keyframes pan {
  0% {
    transform: rotate(0deg);
    transform-origin: top right;
  }
  10% {
    transform: rotate(-2deg);
    transform-origin: top right;
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.pan-loader {
  width: 45%;
  height: 30%;
  margin: 0px auto;
}
.container_loader {
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.pan-loader .loader {
  position: relative;
  top: 10%;
  left: 0;
  z-index: 0;
  width: 60%;
  height: 45%;
  border: 10px solid transparent;
  border-bottom: 9px solid #fdd835;
  border-radius: 50%;
  animation: loader 2s infinite;
  animation-timing-function: linear;
}
.pan-loader .pan-container {
  display: flex;
  width: 100%;
  animation: pan 2s infinite;
}
.pan-loader .pan {
  width: 60%;
  height: 28px;
  background: #e2171a;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.pan-loader .handle {
  width: 40%;
  height: 10px;
  background: #e2171a;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
