body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
}
#root {
  max-width: 430px;
  margin: 0 auto;
  border-left: 1px solid #dededf;
  border-right: 1px solid #dededf;
  font-family: 'Inter', sans-serif;
}
html.is-locked,
html.is-locked body {
  min-height: var(--window-inner-height);
  height: var(--window-inner-height);
  max-height: var(--window-inner-height);
  overflow: hidden;
}
