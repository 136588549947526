.main_app {
  background-color: white;
  height: 100%;
}

.headerPic {
  max-width: 100%;
  max-height: 80%;
  opacity: 0.4;
}

/* Orange Button */
.btn-orange {
  background-color: orange;
  color: #fff;
  margin: 1rem;
}

.btn-orange:hover {
  background-color: orange;
  color: #fff;
  font-weight: bold;
  margin: 1rem;
}

.btn-full-width {
  width: 90%;
}

.btnDisabled {
  pointer-events: none;
  cursor: default !important;
}

/* Hollow Button with orange border */
.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-54 {
  font-size: 54px !important;
}

/* Font weight */
.fw-1000 {
  font-weight: 1000;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

/* Declare all color Variable */
:root {
  --green-color: #27d062;
  --blue-color: #3884fc;
  --shadow-color: #0000002e;
  --dark-color: #000;
  --theme-color: #fd4b48;
  --red-color: #ff6600;
  --white-color: #fff;
  --grey-color: #86898f;
  --grey1-color: #808080;
  --grey2-color: #5c5c5c;
  --itemStatusGreen-color: #18a679;
  --moccasin-color: #ffe4bb;
  --promoBlue-color: #282c3f;
  --promoTitleBlue-color: #4e5265;
  --redText-color: #fd4b48;
  --lightRed-color: #fd4b48;
  --redOutline-color: #dc0c10;
  --darkRed-color: #e2171a;
  --app-red-color: #ef220c;
  --transRed-color: #fef3f2;
  --inputFocus-color: #98e0b6;
  --light-green: #89d2a8;
  --errorSuccess-color: #fde7e6;
  --information-color: #fff2d5;
  --informationText-color: #282c3f;
  --notch-color: #282c3f;
  --darkGreen-color: #006400;
  --link-color: #1d6be1;
  --city-color: #86898f;
}

/* Text color */
.redtext {
  color: var(--red-color);
}

.darkColor {
  color: var(--dark-color);
}

.greenText {
  color: var(--green-color);
}

.blueText {
  color: var(--blue-color) !important;
}

.whiteText {
  color: var(--white-color);
}

/* Box Shadow */
.boxShadow {
  box-shadow: 0px 0px 6px var(--shadow-color);
}

.itemShadow {
  box-shadow: var(--shadow-color) 0px 1px 8px 0px;
}

.subText {
  color: var(--grey-color);
}

/* Opacity Class */
.op-0 {
  opacity: 0 !important;
}

.op-30 {
  opacity: 0.3 !important;
}

.op-50 {
  opacity: 0.5 !important;
}

.op-70 {
  opacity: 0.7 !important;
}

.op-80 {
  opacity: 0.8 !important;
}

.op-100 {
  opacity: 1 !important;
}

.disabledBtn {
  opacity: 0.5;
  pointer-events: none;
}

/* Background Color */
.bgGray {
  background-color: var(--grey1-color);
}

/* height */
.height-1 {
  height: 1rem;
}

.simpleHr {
  padding-bottom: 0 !important;
  border-top: 1px solid var(--grey-color) !important;
}

/* required INput Field */

/* placeholder */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--grey2-color);
  opacity: 0.5;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--grey2-color);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--grey2-color);
}

.orange {
  color: var(--theme-color);
}

.loaderStyle {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 20vh; */
}

.separatorLine {
  margin: auto;
  height: 5px;
  background-color: #f7f8f9;
}

.pointer {
  cursor: pointer;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.searchBar {
  width: 100%;
  /* height: 50px; */
  background: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.removeScrollBar::-webkit-scrollbar {
  display: none;
}

.searchInput {
  width: 80%;
  margin-left: 0.5em;
  border: none;
  border: hidden;
  outline: none;
  font-size: 0.8rem;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.deepGreenText {
  color: #0ca52f;
}

.deepRedText {
  color: #b23030;
}

.z-index-0.z-index-0 {
  z-index: 0;
}

.webview-container {
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 428px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.webview-content {
  max-width: 80%;
  position: absolute;
  right: 10px;
  bottom: 20px;
  width: auto;
  border-radius: 5px;
  background: white;
  word-break: break-all;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: nudge 1s infinite;
}

/* Triangle at the bottom */
.webview-content::after {
  content: '';
  position: absolute;
  bottom: -20px;
  right: 0px;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

@keyframes nudge {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}