/* Text Decor  */
.appHeader {
  color: #282c3f !important;
  opacity: 0.8;
}

.appSubHeader {
  color: #282c3f !important;
}

.appBodyContent {
  color: #86898f;
}

.udarrow {
  aspect-ratio: 16/8;
  width: 16px;
}

.textCapitalize {
  text-transform: capitalize;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.flexBox {
  display: flex;
}

.nav {
  z-index: 2;
  position: fixed;
  bottom: 0;
  max-width: 427px;
  border: red;
  width: 100vw;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  /* padding: 0.5rem 0; */
  height: 60px;
  /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); */
  display: flex;
  /* border-top: 1px solid lightcoral; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #ffbcbc4a;
}

@media only screen and (max-height: 450px) {
  .nav {
    z-index: -1;
  }
}

.one {
  background: white;
  position: relative;
  bottom: 105%;
  left: 50%;
}

.one::after,
.one::before {
  position: absolute;
  content: '';
  width: 40px;
  height: 40px;
  background: white;
  bottom: -1em;
  z-index: 100;
  opacity: 0;
  /* box-shadow: 0px -3px 6px #ffbcbc4a; */

  border-bottom: 1px solid #ffbcbc4a;
}

.tostErr {
  z-index: 100;
  background-color: red;
  height: 44vh;
}

.move {
  position: fixed;
  bottom: 7%;
  z-index: 100;
}

.one::before {
  right: 50%;
  transform: skew(0, 22deg);
}

.one::after {
  left: 50%;
  transform: skew(0, -22deg);
}

.nav__link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--grey-color);
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
  line-height: 2;
  cursor: pointer;
}

.nav__text {
  color: #b3b9c4;
  letter-spacing: 0px;
  text-decoration: none;
}

/* .nav__text:active {
  color: red;
} */
.nav__link:hover {
  background-color: #eeeeee;
  color: #282c3f;
  text-decoration: none;
}

.nav__link:active {
  color: red;
}

.nav__icon {
  font-size: 18px;
}

.flexLeft {
  flex: 75%;
}

.flexRight {
  flex: 25%;
  background: no-repeat center center/cover;
  height: 80px;
}

.bottomCenter {
  position: absolute;
  margin-top: 70px;
  margin-left: 23px;
  margin-right: 23px;
}

.m-l-r {
  margin-left: 23px;
  margin-right: 23px;
}

.widthHeight {
  height: 80px;
  width: 90%;
}

.fontStyleXsmall {
  font: normal normal bold 12px/15px Inter;
}

.fontStyleSmall {
  font: normal normal normal 14px/17px Inter;
}

.fontStyleMedium {
  font: normal normal bold 16px/19px Inter;
}


.headerEllipses {
  text-overflow: ellipsis;
  width: 60%;
  white-space: nowrap;
  overflow-x: clip;
}

.text-o-ellipsis {
  text-overflow: ellipsis;
}

.fontStyleSmall {
  font: normal normal normal 12px/15px Inter;
}


.colorDisable {
  color: #b0b0b0;
  text-decoration: line-through red;
  display: block;
  font-size: 12px;
}

/* social media */
.googleBtn {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-size: 0.8rem;
  margin-left: 0.5rem;
  border-radius: 5px !important;
}

.socialMedia_btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
  height: 33px;
  padding: 4px 16px;
  border: 1px solid #d9d9d9;
}

.social-media-btn {
  color: #1d6ce2;
  background-color: var(--white-color) !important;
  border-radius: 5px !important;
  border: none;
  /* width: 140px; */
  height: 30px;
}

.google-btn {
  width: 115px;
}

.facebook-btn {
  width: 130px;
}

.btn:hover {
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 300px) {
  .social-media-btn {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 360px) {}

.r-flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.c-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* buttons */
.buttonColor {
  background: linear-gradient(to bottom right, #fd4b48, #e2171a);
}

.addButton {
  border: 1px solid #d2d2d2;
  border-radius: 20px !important;
}

.addButtonIncrease {
  width: 92px;
  height: 34px;
  background-color: #fff;
  border: 1px solid #dc0c10;
  border-radius: 23px;
  padding-bottom: 2px;
}

.add-button-home {
  background-color: #fff7f7;
}

.highlighted {
  color: #eb2829 !important;
}

.transRed {
  background: var(--transRed-color);
}

.description {
  color: #5b595a;
}

.disableButton {
  background-color: #b0b0b0;
}

.inputBorderColor {
  color: #86898f;
}

.centerContentColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-68 {
  width: 68% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.centerContentRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.selectedField {
  border: 1.2px solid #eb2829 !important;
  color: #eb2829 !important;
  opacity: 1;
  font-weight: 600 !important;
}

textarea,
textarea:focus {
  outline: none !important;
  border-radius: 6px;
  border-color: #86898f;
}

textarea::placeholder {
  font-size: 14px;
  color: #282c38;
  opacity: 0.5;
}

.text-tag {
  height: 1.21rem;
  font-size: 0.73rem;
  font-weight: 400 !important;
  background-color: #fd4b481a;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  opacity: 1;
  border-radius: 0.2rem;
  border: 0.5px solid #fd4b4880;
  font-weight: lighter;
  /* text-transform: capitalize; */
}

.text-mediumtag {
  width: 4.3rem;
  height: 1.219rem;
  font-size: 0.75rem;
  background-color: #56fd481a;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  opacity: 1;
  border-radius: 0.2rem;
  border: 0.5px solid #fd4b4880;
  font-weight: lighter;
}

.text-smalltag {
  width: auto;
  height: auto;
  background-color: #fde7e6;
  text-align: center;
  opacity: 1;
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.8);
  border: 0.1px solid #f75b5b;
  border-radius: 0.1rem;
  padding: 3px 6px;
}

.text-bigtag {
  /* width: 5.2rem !important; */
  max-height: 3rem;
  height: fit-content !important;
  font-size: 0.9rem !important;
  background-color: #fde7e6 !important;
  text-align: center !important;
  opacity: 1 !important;
  font-weight: normal !important;
  border: 0.1px solid #f03232 !important;
  border-radius: 6px !important;
}

.star-rating {
  width: fit-content;
  height: fit-content;
  padding: 2px 4px;
  border-radius: 4px;
}

.ratings-text {
  font-size: 0.65rem;
  margin-top: 0.05rem;
}

.ratings-text-less-than-3 {
  position: relative;
  top: 1px;
  margin-left: -4px;
  color: #535665 !important;
}

.message-error {
  height: 1rem;
  color: var(--redText-color);
  background-color: var(--errorSuccess-color);
  font-size: 0.75rem;
  text-align: center;
  border-radius: 10%;
  padding: 0.1rem;
}

.message-success {
  height: 1rem;
  color: var(--darkGreen-color);
  background-color: var(--errorSuccess-color);
  font-size: 0.75rem;
  text-align: center;
  border-radius: 10%;
  padding: 0.1rem;
}

.message-error>span {
  font-weight: 100;
}

.message-success>span {
  font-weight: 100;
}

.input-label {
  font-size: 0.8rem;
}

.large-input-entry {
  width: 12rem;
  height: 1.7554rem;
  border: 0.5px solid #86898f;
  border-radius: 6px;
  padding: 1.5rem 0rem 1.5rem 0.6rem;
}

@keyframes moveLargeInputPlaceholder {
  from {
    font-size: 0.8rem;
    top: 0rem;
  }

  to {
    font-size: 0.68rem;
    top: -1.35rem;
  }
}

@keyframes moveSmallInputPlaceholder {
  from {
    font-size: 0.75rem;
    top: 0.8rem;
  }

  to {
    font-size: 0.7rem;
    top: -0.5rem;
  }
}

.input-field>label {
  position: relative;
  font-size: 0.8rem;
  left: -11.5rem;
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.large-input-entry:focus+label,
.large-input-entry:not(:placeholder-shown).large-input-entry:not(:focus)+label {
  position: relative;
  font-size: 0.68rem;
  top: -1.6rem;
  left: -11.5rem;
  background-color: white;
  animation: moveLargeInputPlaceholder 0.1s;
}

.small-input-entry:focus+label,
.small-input-entry:not(:placeholder-shown).small-input-entry:not(:focus)+label {
  position: relative;
  font-size: 0.7rem;
  top: -0.5rem;
  left: -7.5rem;
  background-color: white;
  width: 40rem;
  overflow-wrap: normal;
  animation: moveSmallInputPlaceholder 0.1s;
}

.large-input-entry:hover,
.small-input-entry:hover {
  border: 0.05rem solid;
  border-color: var(--blue-color);
}

.small-input-entry {
  width: 8rem;
  height: 2.1875rem;
  border: 0.5px solid #86898f;
  border-radius: 6px;
  opacity: 1;
  padding: 1.4rem 0rem 1.4rem 0.6rem;
}

.small-input-entry::-webkit-input-placeholder {
  font-size: 0.7rem;
}

.double-text-field {
  display: flex;
  justify-content: space-between;
  width: 15rem;
}

.information {
  width: 17rem;
  height: 1.2rem;
  color: var(--informationText-color);
  background-color: var(--information-color);
  font-size: 0.68rem;
  border-radius: 10%;
  font-weight: 450;
  letter-spacing: 0.03rem;
}

.information>p {
  position: relative;
  left: 0.45rem;
}

.double-text-field>label {
  position: relative;
  font-size: 0.75rem;
  top: 0.8rem;
  left: -7.5rem;
  background-color: white;
  width: 30rem;
  height: 1rem;
  white-space: nowrap !important;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.5);
}

.pop-up-closer {
  width: 2.2rem;
  height: 2.2rem;
  display: block;
  position: relative;
  margin-left: 12rem;
  margin-right: 12rem;
  margin-top: 88vh;
  margin-bottom: 25vh;
}

.fw-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold !important;
}

.bg-color-address {
  background-image: linear-gradient(105deg, #ea501f0d, #e01d140d);
}

.bg-color-white {
  background-color: var(--white-color);
}

.bg-color-red {
  background-color: rgba(255, 0, 0, 1);
}

.fw-400 {
  font-weight: 400;
}

.header {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #ffffff;
  box-shadow: 1px 4px 30px #bcbcbc66;
  padding: 0 10px;
  max-width: 428px;
  width: 100%;
}

.safe-postion-fixed-margin {
  height: 55px;
  max-width: 428px;
  width: 100%;
}

.safe-postion-fixed-margin.add-red-bg {
  background: transparent linear-gradient(106deg, #fd4b48 0%, #e2171a 100%) 0% 0% no-repeat padding-box;
}

.header__left {
  margin-right: 10px;
}

.header__left>img .pointer {
  cursor: pointer;
}

.font-weight-lighter {
  font-weight: lighter;
}

@media only screen and (max-width: 430px) {
  .pop-up-closer {
    margin-left: 45vw;
    margin-right: 45vw;
  }

  .notch {
    margin-left: 40vw !important;
    margin-right: 40vw !important;
    width: 12vw !important;
  }
}

@media only screen and (max-height: 600px) {
  .pop-up-closer {
    margin-top: 86vh;
  }
}

@media only screen and (min-height: 731px) and (max-height: 1000px) {
  .pop-up-closer {
    margin-top: 89vh;
  }
}

@media only screen and (min-height: 1001px) and (max-height: 1300px) {
  .pop-up-closer {
    margin-top: 92vh;
  }
}

@media only screen and (min-height: 1301px) {
  .pop-up-closer {
    margin-top: 94vh !important;
  }
}

.notch {
  width: 3rem;
  height: 0.24rem;
  background-color: var(--notch-color);
  margin-left: 10.7rem;
  margin-right: 10.7rem;
  border-radius: 40px;
}

.service-option {
  padding: 0px 20px 5.08px 11.38px;
}

.order-content-header {
  font-size: 1.05rem;
  font-weight: bolder;
}

.active-status {
  color: red !important;
}

.order-content-selected {
  background-color: rgba(255, 0, 0, 0.1);
}

.order-type-value {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.order-image {
  width: 40px !important;
  height: 40px !important;
  margin-right: 4px;
}

.text-header {
  font-size: 20px;
  font-weight: 700;
}

.flex-wrap-90 {
  width: 90% !important;
  flex-wrap: wrap;
}

.dotted-line {
  border: none;
  /* border-top: 1px dotted var(--city-color); */
  background-image: linear-gradient(to right, var(--city-color) 45%, rgba(255, 255, 255, 0) 0%);
  background-size: 4px 3px;
  height: 1px;
  opacity: 0.7;
}

.dashed-line {
  border-bottom: 0.2px dashed #acacac;
  /* background-image: linear-gradient(to right, var(--city-color) 45%, rgba(255, 255, 255, 0) 0%); */
  /* background-size: 2px 3px; */
  height: 0px;
  width: 92%;
}

.dashed-line-allergy {
  margin-left: 8px;
  border-bottom: 2px dashed #e8e8e8;
  height: 0px;
  width: 92%;
  padding-top: 3px;
  margin-bottom: 3px;
}

/* checkbox */
.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.cursor-ptr {
  cursor: pointer;
}

.checkbox>input {
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 4px;
  outline: none;
  background-color: #ffffff;
  cursor: pointer;

  width: 20px;
  height: 20px;
}

.checkbox>input:checked {
  content: '\2713';

  border: 1px solid #eb2829;
  background-color: #eb2829;
}

.checkbox>input:checked+span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 0.2rem;
  top: 1px;
  width: 3px;
  font-size: 16px;
}

.checkbox>input:active {
  border: 2px solid #34495e;
}

/*  */
.red-color {
  color: var(--redText-color) !important;
}

.green-color {
  color: #019e3c !important;
}

.blue-color {
  color: #2341F6 !important;
}

.past-order-container {
  min-height: 1.5rem !important;
}

.upcoming-order-container {
  min-height: 3.5rem !important;
}

.order-history {
  width: 100% !important;
}

.order-history:first-child {
  /* display: none; */
  background-color: red;
}

.order-history-no {
  width: 100% !important;
}

.completed-tag {
  background: #1ace222e 0% 0% no-repeat padding-box !important;
  border: 0.5px solid #1ace2280;
}

.cancelled-tag {
  background: #ce1a1a2e 0% 0% no-repeat padding-box !important;
  border: 0.5px solid #ce1a1a80;
}

.waiting-tag {
  background: #ff6c002e 0% 0% no-repeat padding-box !important;
  /* border: 0.5px solid #ffd80080; */
}

.in-progress-tag {
  background: #ffd8002e 0% 0% no-repeat padding-box !important;
  border: 0.5px solid #ffd80080;
}

.order-container {
  margin: 0 auto;
  min-height: 100vh;
}

.order-history-container {
  padding-bottom: 105px;
  position: relative;
}

.upcoming-order-bg {
  background: transparent linear-gradient(359deg, #d5f6d7 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
}

.schedule-order-text {
  position: relative;
  top: 1rem;
}

.active-order-img>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardContainer_bottom {
  height: 70px;
}

.p-sticky {
  position: sticky;
  top: 0;
}

.op-1 {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 51px;
  max-width: 428px;
  z-index: 1;
  border-bottom-right-radius: 20px;
}

.search-restaurant-input {
  padding-left: 0.8rem;
  border: none;
}

.search-restaurant-input:focus {
  outline: none !important;
}

.search-restaurant-input::placeholder {
  font-size: 14px;
  font-weight: 500;
}

.btn-outlined {
  border: 1px solid var(--darkRed-color) !important;
  background: white !important;
  color: black !important;
  box-shadow: none !important;
  font-size: 14px !important;
  width: fit-content !important;
  height: fit-content !important;
}

.btn-outlined.btn-applied {
  border: none;
  background: var(--darkRed-color) !important;
  color: white !important;
}

.btn:focus {
  box-shadow: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.no-flex-wrap {
  flex-wrap: nowrap;
}

.address-adder {
  border-radius: 20px 20px 0 0;
  position: absolute !important;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: white;
  z-index: 2;
}

.add-address {
  max-width: 428px;
  margin: auto;
  border-radius: 20px 20px 0 0;
  background-color: white;
}

.confirm-delivery-heading {
  font-size: 1.35rem;
  font-weight: 500;
}

.w-95 {
  width: 95% !important;
}

.w-15 {
  width: 15% !important;
}

.address-input {
  position: relative;
}

.address-input-entry {
  padding-left: 1rem;
  padding-right: 5rem;
}

.location-label {
  position: absolute;
  top: -13%;
  left: 3%;
  pointer-events: none;
  opacity: 0.5;
  font-size: 1rem;
  width: 10rem;
}

.change-location-schedule {
  position: absolute;
  top: 32%;
  right: 5%;
  font-size: 0.8rem;
  color: #1d6be1;
}

.address-input-entry:focus+.location-label {
  font-size: 0.8rem;
  opacity: 1;
  color: #1d6be1;
  width: auto;
  background-color: white;
  animation: moveAddressInputPlaceholder 0.1s;
}

.address-input-entry+.location-label {
  font-size: 0.8rem;
  opacity: 1;
  padding: 0 5px;
  width: auto;
  background-color: white;
  animation: moveAddressInputPlaceholder 0.1s;
}

.change-location {
  position: relative;
  float: right;
  font-size: 0.8rem;
  color: blue;
  right: 10%;
  top: -4.65em;
}

/* opacity */
.opacity-70 {
  opacity: 0.7;
}

.opacity-75 {
  opacity: 0.75;
}

.text-align-center {
  text-align: center;
}

.fc-right-arrow {
  color: #dc3545 !important;
  width: 16px !important;
  height: 16px !important;
}

@keyframes moveAddressInputPlaceholder {
  from {
    top: -2.2rem;
    opacity: 0.5;
    font-size: 1rem;
    width: 10rem;
  }

  to {
    top: -3.75rem;
    font-size: 0.8rem;
    opacity: 1;
    width: 5.5rem;
  }
}

.move-up {
  margin-top: -1.5rem !important;
}

.address-box-size {
  padding-left: 1rem;
  width: 95%;
}

.address-box-label {
  position: relative;
  top: -2.2rem;
  left: 1rem;
  pointer-events: none;
  opacity: 0.5;
  font-size: 1rem;
  width: 17rem;
}

.address-box-size:focus+.address-box-label {
  top: -3.75rem;
  left: 1rem;
  font-size: 0.8rem;
  opacity: 1;
  /* color: blue; */
  width: auto;
  background-color: white;
  animation: locationAddressInputPlaceholder 0.1s;
}

.address-box-size:not(:placeholder-shown)+.address-box-label {
  top: -3.75rem;
  left: 1rem;
  font-size: 0.8rem;
  opacity: 1;
  /* color: blue; */
  width: auto;
  background-color: white;
  animation: locationAddressInputPlaceholder 0.1s;
}

@keyframes locationAddressInputPlaceholder {
  from {
    top: -2.2rem;
    opacity: 0.5;
    font-size: 1rem;
    width: auto;
  }

  to {
    top: -3.75rem;
    font-size: 0.8rem;
    opacity: 1;
    width: auto;
  }
}

.landmark-address {
  z-index: 1;
  height: 100vh;
}

.white-color {
  color: var(--white-color);
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.bottom-sticky {
  position: sticky;
  bottom: 0.1rem !important;
}

.bottom-sticky-2 {
  position: sticky;
  bottom: 0.5rem !important;
}

.bottom-sticky-3 {
  position: sticky;
  bottom: 1rem !important;
}

.bottom-fixed {
  position: fixed;
  bottom: 0.5rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.ml-5pt {
  margin-left: 5% !important;
}

.top-sticky {
  position: sticky;
  top: 0;
}

.bot-sticky {
  position: sticky;
  bottom: 0;
}

.hide {
  display: none !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.shift-left {
  position: relative;
  left: calc(20px - 10%);
}

.city-name {
  color: var(--city-color) !important;
}

/* service button */
.serviceBtn {
  border: none;
  background-color: white;
}

.serviceBtn:hover {
  background-color: rgb(242, 241, 241) !important;
}

@media only screen and (max-width: 350px) {
  .shift-left {
    position: relative;
    left: calc(1.4px - 0.3125%);
  }
}

.add-btn-padding {
  padding-top: 0.15rem;
  padding-bottom: 0.1rem;
}

.loyality-cashback {
  height: '35px' !important;
  background-color: 'red' !important;
  border-radius: '5px' !important;
}

.tooltip-design {
  position: relative;
  display: flex;
  /* border-bottom: 1px dotted black; */
}

.tooltip-design .tooltip-text {
  visibility: hidden;
  height: fit-content;
  width: 141px;
  background-color: black;
  color: #fff;
  font-size: 11px;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip-text {
  top: -80px;
  left: -51px;
}

.tooltip-design:hover .tooltip-text {
  visibility: visible;
}

.tooltip-image-dimensions {
  width: 25px;
  height: 25px;
}

.tooltip-text-charges {
  top: -78px !important;
  width: 128px !important;
}

.h-40vh {
  height: 40vh !important;
}

.black-color {
  color: black !important;
}

.whatsapp-icon {
  position: relative;
  left: 5rem;
}

.collapisible-btn {
  margin-top: 2.5rem !important;
}

.no-order-found {
  margin-top: 10vh;
  height: 68vh;
}

.square-radio-btn {
  border: 1px solid #86898f;
  border-radius: 6px;
  height: 72px;
}

.red-border {
  border: 2px solid red;
}

.green-border {
  border: 2px solid #31c16d;
}

.mb-accordian {
  margin-bottom: -1.5rem !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.w-55 {
  width: 55%;
}

.asap {
  width: 79px !important;
}

.service-option>div>i {
  font-size: 20px !important;
}

.heading-order-seperator {
  height: 3rem !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

/* Gaps for flex boxes */
.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-30px {
  gap: 30px;
}

.gap-10px {
  gap: 10px;
}

.gap-12px {
  gap: 12px;
}

.c-gap-2 {
  column-gap: 0.5rem;
}

.opacity-05 {
  opacity: 0.5 !important;
}

.allergy-popup {
  z-index: 2;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-10.z-index-10 {
  z-index: 10;
}

.allergy-color {
  color: var(--app-red-color);
}

.otp-btn-size {
  font-size: 1.1rem !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.text-align-center {
  text-align: center !important;
}

.h-20px {
  height: 20px !important;
}

.h-15px {
  height: 15px !important;
}

.feedback-header {
  background: linear-gradient(to right, #f27628, #dc0c10);
  gap: 12px;
  height: 100px;
  padding: 0 0 20px 20px;
}

.feedback-header>img {
  height: 15px;
}

.feedback-experience {
  padding: 30px 20px 24px 20px;
}

.what-you-like {
  border-radius: 50%;
  gap: 12px;
  width: 20%;
}

.what-you-like>img {
  width: 47px;
  height: 47px;
}

.w-35 {
  width: 35% !important;
}

.ml-10pt {
  margin-left: 10% !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.letter-spacing-halfpixel {
  letter-spacing: 0.5px !important;
}

.order-type-popup {
  z-index: 17;
}

/* Icon Label */
.icon-label {
  height: 12px;
  width: 12px;
  background: red;
  display: block;
  position: absolute;
  top: 3px;
  right: 33%;
  border-radius: 50%;
}

.border-none {
  border: none !important;
}

.border-dotted {
  border-top: 2px dotted rgba(0, 0, 0, 0.1);
}

.selected-category {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.display-none {
  display: none !important;
}

.rotate-collapse-icon-default {
  transform: rotate(0) !important;
  transition: transform 0.2s ease-in-out;
}

.rotate-collapse-icon {
  transform: rotate(180deg) !important;
  transition: transform 0.2s ease-in-out;
}

.accordianExpand {
  width: 16px;
  height: 9px;
}

.break-word {
  word-break: break-word;
}

.hide-visibility {
  visibility: hidden !important;
}

.social-tick {
  width: 20px;
  margin-left: 10px;
}

.qr-image {
  opacity: 0.4;
  height: 24px;
  width: 24px;
}

.waveline-class {
  height: 32px;
  margin-left: -2px;
}

/* cols */
.col20 {
  flex: 0 0 20%;
}

.col30 {
  flex: 0 0 30%;
}

.col40 {
  flex: 0 0 40%;
}

.col50 {
  flex: 0 0 50%;
}

.col60 {
  flex: 0 0 60%;
}

.col70 {
  flex: 0 0 70%;
}

.col75 {
  flex: 0 0 75%;
}

.col80 {
  flex: 0 0 80%;
}

.col90 {
  flex: 0 0 90%;
}

.red-bg {
  background-color: #fde7e6;
  color: #fd4b48;
  padding: 4px;
  font-size: 12px;
}

/* white spaces */
.ws-wrap {
  white-space: wrap;
}

.ws-nowrap {
  white-space: nowrap;
}

/* border radius */
.br-1 {
  border-radius: 5px;
}

.br-2 {
  border-radius: 10px;
}

.br-3 {
  border-radius: 20px;
}

/* line height */
.lh-1 {
  line-height: 0.8;
}

.lh-2 {
  line-height: 1;
}

.lh-3 {
  line-height: 1.2;
}

.shadow {
  border-radius: 10px;
  box-shadow: 4px 8px 16px 2px rgb(0 0 0 / 10%);
  background: rgba(255, 255, 255, 1);
}

.food-allergies {
  line-height: 1.4;
}

.error-msg {
  background-color: rgba(253, 231, 230, 0.5);
  width: fit-content;
  height: 25px;
  border-radius: 5px;
}

.food-allergies {
  line-height: 1.4;
}

.ml-2-half {
  margin-left: 2.5% !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.overflow-ellipsis {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ml-10px {
  margin-left: 10px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.mr-20px {
  margin-right: 20px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mx-20px {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-20px {
  margin: 20px !important;
}

.safe-bottom-margin {
  margin-bottom: 4.8rem;
}

.safe-bottom-margin-full {
  margin-bottom: 10rem;
}

.disabled {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.4);
}

/* Padding */
.p-10 {
  padding: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-20 {
  padding: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.position-relative {
  position: relative;
}

.h-70vh {
  height: 70vh;
}

.border-round {
  border-radius: 50%;
}

.rate-description-text {
  text-shadow: 0px 3px 6px #00000029;
}

.hq-restaurant {
  margin-top: 2px;
}

.mt-5x {
  margin-top: 5px;
}

.restaurant-credits {
  border: 0.5px solid #86898f;
  margin: 0px 20px;
  padding: 10px 14px 13px 12px;
  border-radius: 6px;
}

.h-fit-content {
  height: fit-content !important;
}

.crown-icon {
  position: fixed;
  bottom: 35px;
  z-index: 100;
}

.allergy-footer {
  bottom: 0.1rem;
  max-width: 428px;
  width: 100%;
}

.basic-input-entry {
  height: 50px !important;
  border-radius: 6px;
  border: 0.5px solid #86898f;
  font-weight: 300;
  background-color: white;
  padding-left: 4%;
}

.basic-input-entry:focus {
  outline: #31c16d;
  border-color: #31c16d;
}

.input-profile-field {
  position: relative;
}

.basic-input-label {
  position: absolute;
  padding: 3px;
  top: 16%;
  left: 3.5%;
  opacity: 0.5;
  pointer-events: none;
  transition: font-size top opacity background-color left animation;
  transition-delay: 0.01s;
  transition-timing-function: linear;
}

.basic-input-entry:focus+.basic-input-label,
.basic-input-entry:not(:placeholder-shown).basic-input-entry:not(:focus)+.basic-input-label {
  font-size: 11px !important;
  top: -22%;
  opacity: 1;
  background-color: white;
  left: 3%;
}

.text-decoration-none.text-decoration-none.text-decoration-none {
  text-decoration: none;
}

.disable-pointer-event {
  pointer-events: none;
}

.loader.loader {
  height: 100vh;
}

.delete-bin {
  height: 20px;
  width: 18px;
}

.cart-page-header .pointer {
  padding-bottom: 2.5px;
}

.header-background {
  background-color: #da0807;
}

.fixed-top {
  position: fixed;
  top: 0px;
  margin: 0 auto;
  z-index: 2;
}

.mb-30 {
  margin-bottom: 30px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.lh-16 {
  line-height: 16px;
}

.lh-20 {
  line-height: 20px;
}

.h-fc {
  height: fit-content;
}

.pointer-events-none {
  pointer-events: none;
}

.table-change-gif {
  height: 20%;
  width: 100%;
}

.table-change-container {
  background: #F0F0F0;
  border-radius: 4px;
  padding: 7px 25px;
}

.pt-10px {
  padding-top: 10px;
}

.pt-20px.pt-20px {
  padding-top: 20px;
}

.lang-not-selected {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

.lang-selected {
  border: 1px solid #ea4b4b;
  border-radius: 4px;
}

.delivery-inclusive {
  font-weight: 400;
  font-size: 8px;
  color: #333333;
}

.item-out-of-stock {
  color: #666;
  background-color: #F3F3F3;
  border-radius: 23px;
  opacity: 1;
  padding: 4px 10px;
  white-space: nowrap;
  height: fit-content;
}

.gray-scale-img {
  filter: grayscale(100%);
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.br-8px {
  border-radius: 8px
}

.sub-price {
  color: #666;
  font-size: 12px;
  font-weight: 500;
}

.color-666 {
  color: #666
}

.color-333 {
  color: #333
}

.text-align-justify {
  text-align: justify;
}

.clr-f5222d {
  color: #F5222D;
}

.clr-333 {
  color: #333333;
}

.clr-299a27 {
  color: #229A27;
}

.clr-666 {
  color: #666666;
}