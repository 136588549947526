.spinner__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-width: 428px;
  margin: 0 auto;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner__bg {
  display: flex;
  height: 80px;
  width: 80px;
  align-items: center;
  justify-content: center;
  background: rgba(75, 75, 75, 0.2);
  border-radius: 20px;
}

.spinner {
  border-radius: 50%;
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
